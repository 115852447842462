import React, {useState, useRef, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Modal, Card, Button, Col, Row, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Cardview from "../Assets/HubView/Cardview";
import BrowseAsset from "./BrowseAsset";
import Spinner from "../../../components/spinner";
import axios from "axios";
import config from "../../../config.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";

const EditJourneyMap = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const {journeyMap} = location.state;
  const [steps, setSteps] = useState([]);
  const [showAddSection, setShowAddSection] = useState(false);
  const addSectionRef = useRef(null);
  const topSectionRef = useRef(null);
  const [showDeleteStepModal, setShowDeleteStepModal] = useState(false);
  const [stepToDelete, setStepToDelete] = useState(false);
  const [stepToReplace, setStepToReplace] = useState(null); 

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    product: '',
    target_audience: '',
    objective: '',
    campaign_start_date: null,
    campaign_end_date: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const getJourneyMapDetail = () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(`${config.serviceUrl}/journey_maps/get-journey-map-detail/${journeyMap.id}`, configuration)
      .then((response) => {
        const data = response.data;
        setFormData({
          name: data.name || '',
          description: data.description || '',
          category: data.category || '',
          product: data.product || '',
          target_audience: data.target_audience || '',
          objective: data.objective || '',
          campaign_start_date: data.campaign_start_date ? new Date(data.campaign_start_date) : null,
          campaign_end_date: data.campaign_end_date ? new Date(data.campaign_end_date) : null,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const formatDateForBackend = (date) => {
    const date_obj = new Date(date);
    const utcDate = new Date(
      Date.UTC(
        date_obj.getFullYear(),
        date_obj.getMonth(),
        date_obj.getDate(),
        date_obj.getHours(),
        date_obj.getMinutes(),
        date_obj.getSeconds()
      )
    );
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      campaign_start_date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      campaign_end_date: date,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        errors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
      }
    });
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const authToken = localStorage.getItem("token");
      const configuration = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const formattedStartDate = formatDateForBackend(
        formData.campaign_start_date
      );
      const formattedEndDate = formatDateForBackend(formData.campaign_end_date);
      const updatedData = {
        ...formData,
        campaign_start_date: formattedStartDate,
        campaign_end_date: formattedEndDate,
      };
      const requestBody = {
        journey_map_id: journeyMap.id,
        updated_data: updatedData,
      };
      axios
        .put(
          `${config.serviceUrl}/journey_maps/update-journey-map`,
          JSON.stringify(requestBody),
          configuration
        )
        .then((response) => {
          setMessage(response.data.message);
          setShowSuccessMessage(true);
          setIsEditing(false);
        })
        .catch((error) => {
          setMessage(error.response.data.error);
          setShowErrorMessage(true);
        })
        .finally(() => {
          getJourneyMapDetail();
          topSectionRef.current.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
          }, 3000);
        });
    }
  };

  const getAllSteps = () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serviceUrl}/journey_maps/get-journey-map-steps`,
        {journey_map_id: journeyMap.id},
        configuration
      )
      .then((response) => {
        setSteps(response.data.data.map((step) => step.asset));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveAllSteps = (updatedSteps) => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serviceUrl}/journey_maps/add-journey-map-steps`,
        {
          journey_map_id: journeyMap.id,
          steps: updatedSteps.map((step, index) => ({
            step_id: index + 1,
            asset_id: step.id,
          })),
        },
        configuration
      )
      .then((response) => {
        setMessage(response.data.message);
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        setMessage(error.response.data.error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        getAllSteps();
        setTimeout(() => {
          setShowSuccessMessage(false);
          setShowErrorMessage(false);
        }, 3000);
      });
  };

  const handleAddStepToggle = (index = null) => () => { // Updated function
    setStepToReplace(index); // Set the step to replace
    setShowAddSection(!showAddSection);
    if (!showAddSection) {
      setTimeout(() => {
        addSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleSaveStep = (image) => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      if (stepToReplace !== null) {
        updatedSteps[stepToReplace] = image; // Replace the existing asset
        setStepToReplace(null);
      } else {
        updatedSteps.push(image); // Add new asset if not replacing
      }
      setShowAddSection(false);
      handleSaveAllSteps(updatedSteps);
      return updatedSteps;
    });
  };

  const handleDeleteStep = (index) => {
    setShowDeleteStepModal(false);
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.filter((_, i) => i !== index);
      handleSaveAllSteps(updatedSteps);
      return updatedSteps;
    });
  };

  const openDeleteStepModal = (index) => {
    setStepToDelete(index);
    setShowDeleteStepModal(true);
  };

  const hideDeleteStepModal = () => setShowDeleteStepModal(false);

  const handleCancel = () => {
    setShowAddSection(false);
  };

  const handleclose = () => {
    setIsEditing(false)
    getJourneyMapDetail();
  }

  useEffect(() => {
    getJourneyMapDetail();
    getAllSteps();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="container-fluid position-relative z-index-1"
      style={{top: "58px", marginBottom: "100px"}} ref={topSectionRef}>
      <Card className="mt-3 rounded-bottom">
        <Card.Header className="custom-bg text-white border-light">
          <div className="d-flex justify-content-between">
            {isEditing ? (
              <div className="d-flex align-items-center">
              <b className="me-2">Name:</b>
              <Form.Control
                className="custom-bg text-white editing-input"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                isInvalid={formErrors.name}
              />
              </div>
            ) : (
              <h4>{formData.name}</h4>
            )}
            {showSuccessMessage && (
              <div className="col d-flex justify-content-center">
                <div className="alert alert-success p-2 m-0 fs-5" role="alert">
                  {message}
                </div>
              </div>
            )}
            {showErrorMessage && (
              <div className="col d-flex justify-content-start">
                <div className="alert alert-danger p-2 m-0 fs-5" role="alert">
                  {message}
                </div>
              </div>
            )}
            {!isEditing && (
              <Button variant="primary" onClick={() => setIsEditing(true)}>
                <i className="bi bi-pencil-square me-1"></i> Edit
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body className="custom-bg text-white rounded-bottom">
      <Form onSubmit={handleSubmit} className="white-labels">
        <Row className="mb-2">
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Description:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formData.description}</p>
            ) : (
              <>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  isInvalid={formErrors.description}
                  className="custom-bg text-white editing-input"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.description}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Category:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formData.category}</p>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  isInvalid={formErrors.category}
                  className="custom-bg text-white editing-input"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.category}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Product:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formData.product}</p>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  isInvalid={formErrors.product}
                  className="custom-bg text-white editing-input"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.product}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Target Audience:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formData.target_audience}</p>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="target_audience"
                  value={formData.target_audience}
                  onChange={handleChange}
                  isInvalid={formErrors.target_audience}
                  className="custom-bg text-white editing-input"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.target_audience}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Objective:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formData.objective}</p>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="objective"
                  value={formData.objective}
                  onChange={handleChange}
                  isInvalid={formErrors.objective}
                  className="custom-bg text-white editing-input"
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.objective}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Campaign Start Date:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formatDateForBackend(formData.campaign_start_date)}</p>
            ) : (
              <>
                <DatePicker
                  selected={formData.campaign_start_date}
                  onChange={handleStartDateChange}
                  dateFormat="MMM dd, yyyy"
                  className="custom-bg text-white border-white rounded editing-input"
                  placeholderText="Start Date"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  maxDate={new Date()}
                  disabled={!isEditing}
                />
                {formErrors.campaign_start_date && (
                  <div className="invalid-feedback">
                    {formErrors.campaign_start_date}
                  </div>
                )}
              </>
            )}
          </Form.Group>
          <Form.Group as={Col} className="d-flex">
            <Form.Label className="me-2">Campaign End Date:</Form.Label>
            {!isEditing ? (
              <p className="mb-2">{formatDateForBackend(formData.campaign_end_date)}</p>
            ) : (
              <>
                <DatePicker
                  selected={formData.campaign_end_date}
                  onChange={handleEndDateChange}
                  dateFormat="MMM dd, yyyy"
                  className="custom-bg text-white border-white rounded editing-input"
                  placeholderText="End Date"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={formData.campaign_start_date ? new Date(formData.campaign_start_date) : null}
                  maxDate={new Date()}
                  disabled={!isEditing}
                />
                {formErrors.campaign_end_date && (
                  <div className="invalid-feedback">
                    {formErrors.campaign_end_date}
                  </div>
                )}
              </>
            )}
          </Form.Group>
        </Row>

        {isEditing && (
          <Row className="mb-2">
            <Col className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={handleclose}
                className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Card.Body>
      </Card>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Row className="mt-3">
            {steps.map((step, index) => (
              <Col md="auto" className="mb-2" key={index}>
                <Card
                  className="card-modal journeymap-card border-0 pb-0"
                  style={{width: "18rem"}}>
                  <Card.Body className="rounded-bottom">
                    <Cardview
                      isjourneyMap={true}
                      asset={step}
                      handleImageClick={() => {}} // No operation for image click
                    />
                    <Button
                      variant="danger"
                      size="sm"
                      title="Delete Step"
                      onClick={() => {
                        openDeleteStepModal(index);
                      }}
                      className="mt-2 me-2">
                      <i className="bi bi-trash"></i>
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="mt-2"
                      onClick={handleAddStepToggle(index)}>
                      Replace
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Col md="auto" className="mb-2">
              <Card
                className="text-center"
                style={{width: "150px", cursor: "pointer"}}
                onClick={handleAddStepToggle()}>
                <Card.Body
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{height: "150px"}}>
                  <i className="bi bi-plus-circle fs-1" />
                  <span className="mt-2">Add Step {steps.length + 1}</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {showAddSection && (
            <BrowseAsset
              handleSaveStep={handleSaveStep}
              handleCancel={handleCancel}
              addSectionRef={addSectionRef} // Pass handleSaveStep as handleImageClick
            />
          )}
        </>
      )}
      {/* Delete Modal */}
      <Modal show={showDeleteStepModal} onHide={hideDeleteStepModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Step</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <h5> Are you sure you want to delete ?</h5>
        </div>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={hideDeleteStepModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleDeleteStep(stepToDelete)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditJourneyMap;
